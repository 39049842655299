import API, { graphqlOperation } from "@aws-amplify/api";
import { Link } from "gatsby";
import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { LoadMaskContext } from "../../provider/loadmaskprovider";
import { UserTenantContext } from "../../provider/usertenantprovider";
import * as buttonStyles from "../button.module.css";
import * as enrollerStyles from "../enroller.module.css";
import * as formStyles from "../../styles/form-elements.module.css";
import OrderItem from "./order-item";
import * as ordersStyles from "./orders.module.css";
import * as typographyStyles from "../../styles/typography.module.css";

const listOrders = /* GraphQL */ `
query litsOrdersByTenantIdNameAndDate(
    $tenantId: String
    $orderDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
) {
    listOrdersByTenantIdAndDate(
        tenantId: $tenantId
        orderDate: $orderDate
        sortDirection: $sortDirection
        limit: $limit
        nextToken: $nextToken
    ) {
        items {
            id
            group
            enrollerConfigurationName
            placedBy
            orderDate
            itemCode
            itemDescription
            quantity
            unitOfMeasure
            shipToName
            shipToAddress
            shipToState
            shipToCity
            shipToZip
            status
        }
        nextToken
    }
}
`

const updateOrder = /* GraphQL */`
mutation updateOrder(
    $input: UpdateOrderInput!
) {
    updateOrder(input: $input) {
        id
        status
    }
}
`

const TestOrderList = ({ userContext, isCoordinator }) => {
    const [isLoading, setIsLoading] = useContext(LoadMaskContext);
    const [userTenantContext, setUserTenantContext] = useContext(UserTenantContext);
    const [state, setState] = useState({
        tenant: null,
        enrollerConfig: null,
        nextToken: null,
        orders: [],
    });
    const [showMessage, setShowMessage] = useState(false);

    const options = userTenantContext.filter(t => t.tenantRole.includes("Admin")).map(tenant => {
        return {
            value: tenant.tenantObj.name,
            label: tenant.tenantObj.prettyName,
        }
    });

    const lookup = async (tenant) => {
        const filter = {
            status: {eq: "CREATED"},
        }
        setIsLoading(true);
        try {
            let results = await API.graphql(graphqlOperation(listOrders, {tenantId: tenant, orderDate: { le: new Date() }, sortDirection: "DESC",}));
            setState({
                ...state,
              orders: results.data.listOrdersByTenantIdAndDate.items,
              nextToken: results.data.listOrdersByTenantIdAndDate.nextToken,
            });
            if (results.data.listOrdersByTenantIdAndDate.items.length == 0) {
                setShowMessage(true);
            }
        } finally {
            setIsLoading(false);
        }
    }

    const update = async (orderId, status) => {
        setIsLoading(true);
        try {
            const result = await API.graphql(graphqlOperation(updateOrder, {
                input: {
                    id: orderId,
                    status: status,
                }
            }));
            let orderIdx = state.orders.findIndex(order => order.id == result.data.updateOrder.id);;
            state.orders[orderIdx].status = status;
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            <div className={typographyStyles.headerWithButton}>
                <h1>Orders</h1>
                <div>
                    <Link to="/manager/orders/new" className={buttonStyles.button}>Create new order</Link>
                </div>
            </div>
            <div className={enrollerStyles.form}>
                <div className={enrollerStyles.inputWrapper}>
                    <label className={enrollerStyles.label}>Tenant</label>
                    <Select
                        options={options}
                        onChange={e => {setState({...state, tenant: e.value});lookup(e.value)}}
                    />
                </div>
            </div>
            <div className={`${state.orders.length > 0 ? ordersStyles.orderList : ""}`}>
                {state.orders.map(order => <OrderItem key={order.id} order={order} update={update} isCoordinator={isCoordinator} />)}
            </div>
            {showMessage && <p style={{ marginTop: "2rem" }}>No outstanding orders.</p>}
        </>
    );
}

export default TestOrderList;