import React from "react";
import * as ordersStyles from "./orders.module.css";
import * as buttonStyles from "../button.module.css";

const OrderItem = ({ order, update, isCoordinator }) => {
    let measureUnit = order.unitOfMeasure === "CASE" ? "case" : "unit";
    if (order.quantity > 1) {
        measureUnit += "s";
    }
    return (
        <div className={ordersStyles.orderItem}>
            <div className={ordersStyles.orderItemHeader}>
                <strong>Purchase Order # {order.id}</strong>
                <span className={`${ordersStyles.statusBadge} ${order.status == 'DENIED' ? ordersStyles.red : ordersStyles.green}`}>{order.status}</span>
            </div>
            <p>
                <strong>Task Order</strong>{' '}
                <span>{order.enrollerConfigurationName}</span>
            </p>
            <p>{order.quantity} {measureUnit} of {order.itemDescription} ({order.itemCode}) ordered by {order.placedBy} on {new Date(order.orderDate).toLocaleDateString()} to be delivered to {order.shipToAddress}, {order.shipToCity}, {order.shipToState}.</p>
            {isCoordinator && order.status == 'CREATED' && <div className={ordersStyles.actions}>
                <button type="button" className={buttonStyles.approve} onClick={() => update(order.id, 'APPROVED')}>Approve</button>
                <button type="button" className={buttonStyles.deny} onClick={() => update(order.id, 'DENIED')}>Deny</button>
            </div>}
        </div>
    );
}

export default OrderItem;