import React, { useContext } from "react";
import SEO from "../../../components/seo";
import CustomAuth from "../../../components/custom-auth";
import TestOrderList from "../../../components/orders/test-order-list";
import { UserContext } from "../../../provider/usercontextprovider";
import { UserTenantProvider } from "../../../provider/usertenantprovider";

const ProtectedPage = () => {
    const [userContext] = useContext(UserContext);
    const isLogistician =
        userContext.username &&
        userContext.signInUserSession.accessToken.payload["cognito:groups"].includes("Logistician");
      const isCoordinator =
        userContext.username &&
        userContext.signInUserSession.accessToken.payload["cognito:groups"].includes("Coordinator");

    return (
        <CustomAuth>
            <SEO title="Medical Test Orders" />
            <UserTenantProvider>
              {isLogistician ? <TestOrderList userContext={userContext} isCoordinator={isCoordinator} /> : <p>User does not have correct permissions to access this page</p>}
            </UserTenantProvider>
        </CustomAuth>
    );
}

export default ProtectedPage;